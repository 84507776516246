import { ChangeDetectionStrategy, Component, HostListener } from '@angular/core';
import { BurgerBarService } from './burger-bar.service';
import { ActiveSecurityContextStateService } from '@security/active-security/active-security-context.state-service';
import { AppLayoutStateService } from '../app-layout.state-service';
import { ActiveFeatureService } from '@app/active-feature.service';
import { RouteAction } from '@design/buttons/action-config';
import { ActiveCintraProductService } from '@app/active-cintra-product.service';
import { getProductHelpUrl } from '@app/cintra-product';

/**
 * Slide-out navigation menu contains a configurable list of permission-based menu items.
 * This component is embedded in {@link AppLayoutComponent}'s Material Sidebar as the MatDrawer.
 * It contains the current user's name and my-profile image so has dependencies on the models services for those.
 *
 * see {@link BurgerBarConfig}
 */
@Component({
  selector: 'app-burger-bar',
  templateUrl: './burger-bar.component.html',
  styleUrls: ['./burger-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BurgerBarComponent {
  getProductHelpUrl = getProductHelpUrl;
  constructor(
    public appLayoutStateService: AppLayoutStateService,
    public burgerBarService: BurgerBarService,
    public activeSecurity: ActiveSecurityContextStateService,
    public appLayout: AppLayoutStateService,
    private activeFeatureService: ActiveFeatureService,
    public activeProductService: ActiveCintraProductService
  ) {}

  /**
   * Accessibility feature that allows
   * 1. a menu item to be activated by keying enter
   * 2. the burger bar to be closed by keying escape
   */
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter' && (event.target as HTMLElement).classList.contains('bar-link')) {
      this.burgerBarService.toggleBurgerBar(false);
      (event.target as HTMLElement).click();
    } else if (event.key === 'Escape') {
      this.burgerBarService.toggleBurgerBar(false);
    }
  }

  isActive = (item: RouteAction) => {
    const isActive =
      (!item.section && item.feature === this.activeFeatureService.activeFeature$.value) ||
      (!!item.section && item.section === this.activeFeatureService.activeSection$.value);
    return isActive;
  };
  m;
  isImpersonated = () => this.activeSecurity.isImpersonated();

  endUserImpersonation = () => this.activeSecurity.endUserImpersonation();
}
