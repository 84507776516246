<a
  class="burger-bar-link"
  [tid]="getTestId(item.label)"
  [ngClass]="{ active: isActive }"
  (click)="burgerBarService.navigate(item)"
  (click)="navigateItem(item)"
  tabindex="0"
>
  <mat-icon *ngIf="item.icon">{{ item.icon }}</mat-icon>
  <span>{{ item.label }}</span>
</a>
