import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OrganisationPayrollsDataProvider, OrganisationPayrollsStateService } from '@shared-payrolls';
import { Router } from '@angular/router';
import { ActiveSecurityContextStateService } from '@security/active-security/active-security-context.state-service';
import { bureauRoutes } from '@app/bureau/bureau-routes';
import { ActiveCintraProductService } from '@app/active-cintra-product.service';
import { organisationRoutes } from '@security/organisations/organisation-routes';
import { map } from 'rxjs';
import { take } from 'rxjs/operators';
import { TabNavigationDataset } from '@app/app-layout/header/mobile-tab-navigation/mobile-tab-entity';
import { getAdminHubLandingRoute } from '@app/admin-hub/admin-hub-routes';

@UntilDestroy()
@Component({
  selector: 'app-mobile-tab-navigation',
  templateUrl: './mobile-tab-navigation.component.html',
  styleUrls: ['./mobile-tab-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [OrganisationPayrollsStateService, OrganisationPayrollsDataProvider]
})
export class MobileTabNavigationComponent {
  canAccessEmployeeHub = this.activeSecurity.canAccessEmployeeHub();
  canAccessCintraPayrolls = this.activeSecurity.canAccessCintraPayrolls();
  canAccessAdminHub = this.activeSecurity.canAccessAdminHub();
  canAccessBureau = this.activeSecurity.hasApplicationAuthorityTo('EditBureaux') || this.activeSecurity.hasBureauProfilePermissions();

  tabsDataset: TabNavigationDataset;
  currentArea$;
  selectedItemId;
  useSelect = false;
  pickerMenuOpen = false;

  constructor(
    private router: Router,
    public activeSecurity: ActiveSecurityContextStateService,
    public activeCintraProductService: ActiveCintraProductService
  ) {
    this.bindNavigation();
  }

  navigate(item) {
    this.currentArea$.pipe(take(1)).subscribe((area) => {
      if (area.id != item.id) {
        switch (item.id) {
          case 1:
            this.router.navigateByUrl(bureauRoutes.organisations).then();
            break;
          case 2:
            if (this.activeSecurity.hasApplicationAuthorityTo('EditOrganisations')) {
              this.router.navigateByUrl(`/${organisationRoutes.hub}`).then();
            } else {
              this.activeSecurity.navigatePayrolls();
            }
            break;
          case 3:
            this.router.navigateByUrl('/employee-hub/dashboard').then();
            break;
          case 4:
            this.router.navigateByUrl(getAdminHubLandingRoute(this.activeSecurity)).then();
            break;
        }
      }
    });

    this.pickerMenuOpen = false;
  }

  togglePicker() {
    if (this.useSelect) this.pickerMenuOpen = !this.pickerMenuOpen;
    else this.pickerMenuOpen = false;
  }

  bindNavigation() {
    let tabsData = [];

    if (this.canAccessBureau) {
      tabsData.push({
        id: 1,
        name: 'Bureau',
        imageSrc: '/assets/img/BureauActive.svg',
        backgroundColor: 'bureau-blue',
        altText: 'Bureau icon'
      });
    }
    if (this.canAccessCintraPayrolls) {
      tabsData.push({
        id: 2,
        name: 'Payrolls',
        imageSrc: '/assets/img/PayrollOutlineActive.svg',
        backgroundColor: 'primary',
        altText: 'Payroll icon'
      });
    }
    if (this.canAccessEmployeeHub) {
      tabsData.push({
        id: 3,
        name: 'Employee Hub',
        imageSrc: '/assets/img/EmployeeHubActive.svg',
        backgroundColor: 'employee-hub-blue',
        altText: 'Employee hub icon'
      });
    }
    if (this.canAccessAdminHub) {
      tabsData.push({
        id: 4,
        name: 'Admin Hub',
        imageSrc: '/assets/img/AdminHubActive.svg',
        backgroundColor: 'system-purple',
        altText: 'Admin hub icon'
      });
    }

    this.useSelect = tabsData.length > 1;

    this.tabsDataset = new TabNavigationDataset(tabsData);

    this.currentArea$ = this.activeCintraProductService.activeProduct$.pipe(
      map((productName) => {
        switch (productName) {
          case 'Bureau':
            this.selectedItemId = 1;
            return tabsData.find((t) => t.id == 1);
          case 'Payroll':
            this.selectedItemId = 2;
            return tabsData.find((t) => t.id == 2);
          case 'EmployeeHub':
            this.selectedItemId = 3;
            return tabsData.find((t) => t.id == 3);
          case 'AdminHub':
            this.selectedItemId = 4;
            return tabsData.find((t) => t.id == 4);
        }
      })
    );
  }
}
