<ng-container *ngIf="hasMultipleMemberships$ | async">
  <button
    tid="organisation-menu-button"
    *ngIf="activeMembership$ | async as activeMembership"
    [matMenuTriggerFor]="organisationMenu"
    class="menu-trigger"
    tabindex="0"
    (click)="focusSearch()"
    #orgOpener
  >
    <span>{{ activeMembership.organisationName }}</span>
    <mat-icon class="trigger-icon">arrow_drop_down</mat-icon>
  </button>

  <form [formGroup]="formGroup">
    <mat-menu #organisationMenu tid="organisation-menu" class="context-menu" style="width: 32rem">
      <app-search-input
        class="org-searcher"
        #searchInput
        formPropertyName="searchTerm"
        (click)="$event.stopPropagation()"
        (keydown)="$event.stopPropagation()"
        [autocomplete]="'off'"
        placeholder="Search"
        tabindex="0"
        forContextMenu="true"
      ></app-search-input>
      <button *ngFor="let membership of filterSet$ | async" mat-menu-item (click)="changeOrg(membership)" tabindex="0">
        {{ membership.organisationName }}
      </button>
      <app-body-md bold id="no-results" *ngIf="!resultCount">No organisations found</app-body-md>
    </mat-menu>
  </form>
</ng-container>
<ng-container *ngIf="(hasMultipleMemberships$ | async) === false">
  <div class="selected-label" *ngIf="activeMembership$ | async as activeMembership" tid="single-membership-label">
    <span>{{ activeMembership.organisationName }}</span>
  </div>
</ng-container>
