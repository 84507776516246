<div class="form">
  <div style="text-align: center">
    <app-cintra-cloud-logo title="Welcome to Cintra&trade; Cloud"></app-cintra-cloud-logo>
  </div>
  <h1>Welcome</h1>
  <ng-content></ng-content>
</div>

<div class="frieze">
  <img src="/assets/img/sign-in-frieze.svg" alt="App frieze image" />
</div>
