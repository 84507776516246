import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActiveSecurityContextStateService } from '@security/active-security/active-security-context.state-service';
import { AbstractEntity } from '@entity-framework/entity-record/state/abstract-entity';
import { bureauRoutes } from '@app/bureau/bureau-routes';
import { ActiveCintraProductService } from '@app/active-cintra-product.service';

/**
 * Material menu which presents the list of Organisations the user can select to work with.
 * Embedded in the {@link HeaderComponent}.
 */
@UntilDestroy()
@Component({
  selector: 'app-bureau-menu',
  templateUrl: './bureau-menu.component.html',
  styleUrls: ['../_context-menu.scss'],
  providers: [FormGroupDirective]
})
export class BureauMenuComponent implements OnInit {
  hasMultiple$ = this.activeSecurity.activeUser$.pipe(
    take(1),
    map((user) => user?.bureauProfiles?.length > 1)
  );
  formGroup?: UntypedFormGroup;
  resultCount: number;

  @ViewChild('searchInput') searchInput: { elementRef: { nativeElement: { focus: () => void } } };

  public filterSet$: BehaviorSubject<AbstractEntity[]> = new BehaviorSubject<AbstractEntity[]>([]);

  constructor(public activeSecurity: ActiveSecurityContextStateService) {
    this.formGroup = new UntypedFormGroup({
      searchTerm: new UntypedFormControl('')
    });

    this.filterSet$.pipe(untilDestroyed(this)).subscribe((bureauProfiles) => {
      this.resultCount = bureauProfiles.length;
    });
  }

  ngOnInit() {
    this.activeSecurity.activeUser$
      .pipe(
        filter((user) => user != null),
        take(1)
      )
      .subscribe((user) => {
        this.filterSet$.next(user.bureauProfiles || []);
        this.resultCount = user.bureauProfiles.length;
      });

    this.formGroup.controls.searchTerm.valueChanges.pipe(untilDestroyed(this)).subscribe((searchTerm: string) => {
      this.filter(searchTerm);
    });
  }

  focusSearch() {
    this.formGroup.controls.searchTerm.setValue('');
    this.searchInput.elementRef.nativeElement.focus();
  }

  filter(searchTerm: string) {
    this.activeSecurity.activeUser$
      .pipe(
        filter((user) => user != null),
        take(1)
      )
      .subscribe((user) => {
        if (!searchTerm) {
          this.filterSet$.next(user.bureauProfiles.slice());
          return;
        } else {
          searchTerm = searchTerm.toLowerCase();
        }

        this.filterSet$.next(user.bureauProfiles.filter((item) => item.name.toLowerCase().indexOf(searchTerm) > -1));
      });
  }

  changeBureau(bureauProfile: AbstractEntity) {
    this.activeSecurity.setActiveBureau(bureauProfile.id);
    ActiveCintraProductService.cachedActiveProduct = 'Bureau';
    window.location.href = '/';
  }
}
