import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { ActiveSecurityContextStateService } from '@security/active-security/active-security-context.state-service';
import { BurgerBarService } from '../../../burger-bar/burger-bar.service';
import { EmployerStatisticsStateService } from '@app/employers/dashboard/state/employer-statistics.state-service';
import { EmployersStateService } from '@app/employers/employer-select/state/employers-state.service';
import { EmployerStatisticsDataProvider } from '@app/employers/dashboard/state/employer-statistics.data-provider';
import { EmployersDataProvider } from '@app/employers/employer-select/state/employers-data.provider';
import { TopNavigationLink } from '@app/app-layout/header/product-navigation/navigation-bar/navigation-bar';
import { FeatureFlagService, FeatureFlagsServiceInjectionToken } from '@feature-flags/feature-flag.service';
import { BehaviorSubject, combineLatest, of, take } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AppClientTypesStateService } from '@app/integrations-admin/integrations/shared/state/app-client-types/app-client-types.state-service';
import { CintraPayNavigationService } from '@app/app-layout/header/product-navigation/cintra-pay-navigation/cintra-pay-navigation.service';

@UntilDestroy()
@Component({
  selector: 'app-cintra-pay-navigation',
  templateUrl: './cintra-pay-navigation.component.html',
  styleUrls: ['./cintra-pay-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [EmployerStatisticsDataProvider, EmployersDataProvider, EmployerStatisticsStateService, EmployersStateService]
})
export class CintraPayNavigationComponent {
  cintraPayLinksBehaviorSubject: BehaviorSubject<TopNavigationLink[]> = new BehaviorSubject<TopNavigationLink[]>([]);
  cintraPayLinks$ = this.cintraPayLinksBehaviorSubject.asObservable();

  constructor(
    public activeSecurity: ActiveSecurityContextStateService,
    public burgerBarService: BurgerBarService,
    @Inject(FeatureFlagsServiceInjectionToken) public featureFlagService: FeatureFlagService,
    private employersStateService: EmployersStateService,
    private appClientTypeStateService: AppClientTypesStateService,
    public cintraPayNavigationService: CintraPayNavigationService
  ) {
    this.createCintraPayLinks();
  }

  createCintraPayLinks = () => {
    let toResolve = {
      featureFlags: this.featureFlagService.getAllValues$().pipe(take(1)),
      hrEnabled: of(false) as any,
      member: this.activeSecurity.activeMembership$
    };

    if (this.activeSecurity.hasOrganisationAuthorityTo('AccessPayrolls')) {
      toResolve.hrEnabled = this.appClientTypeStateService.hasHrEnabled$();
    }

    combineLatest(toResolve).subscribe((result) => {
      const payrollLinks = this.cintraPayNavigationService.fetchCintraPayNavigation(
        result.featureFlags,
        result.hrEnabled as boolean,
        result.member
      );
      this.cintraPayLinksBehaviorSubject.next(payrollLinks);
    });
  };
}
