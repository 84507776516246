import { AbstractEntity } from '@entity-framework/entity-record/state/abstract-entity';
import { AbstractDataset } from '@entity-framework/datasets/abstract-dataset';

export class TabNavigationDataset extends AbstractDataset<TabNavigationEntity, any> {
  getLabel(item: AbstractEntity): string {
    return item.name;
  }

  getValue(item: AbstractEntity): AbstractEntity {
    return item.id;
  }
}

export interface TabNavigationEntity extends AbstractEntity {
  imageSrc: string;

  backgroundColor: string;

  altText: string;
}
