import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignInComponent {
  sso: boolean = false;

  constructor(router: Router) {
    this.sso = router.url.match(/top:sso/gi) != null;
  }
}
