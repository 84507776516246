import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActiveSecurityContextStateService } from '@security/active-security/active-security-context.state-service';
import { Member } from '@security/users/models/member';
import { AuthenticationService } from '@auth-n/authentication.service';

/**
 * Material menu which presents the list of Organisations the user can select to work with.
 * Embedded in the {@link HeaderComponent}.
 */
@UntilDestroy()
@Component({
  selector: 'app-organisation-menu',
  templateUrl: './organisation-menu.component.html',
  styleUrls: ['../_context-menu.scss'],
  providers: [FormGroupDirective]
})
export class OrganisationMenuComponent implements OnInit {
  hasMultipleMemberships$ = this.activeSecurity.hasMultipleMembershipsWithAdminAccess$;
  activeMembership$ = this.activeSecurity.activeMembership$;
  searchTerm: string = '';
  formGroup?: UntypedFormGroup;
  resultCount: number;

  @ViewChild('searchInput') searchInput;
  @ViewChild('orgOpener') orgOpener: ElementRef;

  public filterSet$: ReplaySubject<Member[]> = new ReplaySubject<Member[]>(1);

  constructor(
    public authenticationService: AuthenticationService,
    public activeSecurity: ActiveSecurityContextStateService
  ) {
    this.formGroup = new UntypedFormGroup({
      searchTerm: new UntypedFormControl('')
    });

    this.filterSet$.pipe(untilDestroyed(this)).subscribe((memberships) => {
      this.resultCount = memberships.length;
    });
  }

  ngOnInit() {
    this.activeSecurity.membershipsWithAdminAccess$.pipe(take(1)).subscribe((memberships) => {
      this.filterSet$.next(memberships);
      this.resultCount = memberships.length;
    });

    this.formGroup.controls.searchTerm.valueChanges.pipe(untilDestroyed(this)).subscribe((item) => {
      this.filterMyOptions(item);
    });
  }

  changeOrg(membership: Member) {
    ActiveSecurityContextStateService.cachedOrganisationId = membership.organisationId;
    window.location.href = '/';
  }

  focusSearch() {
    this.formGroup.controls.searchTerm.setValue('');
    this.searchInput.elementRef.nativeElement.focus();
  }

  filterMyOptions(target) {
    this.activeSecurity.membershipsWithAdminAccess$.pipe(take(1)).subscribe((memberships) => {
      if (!target) {
        this.filterSet$.next(memberships.slice());
        return;
      } else {
        target = target.toLowerCase();
      }

      this.filterSet$.next(memberships.filter((item) => item.organisationName.toLowerCase().indexOf(target) > -1));
    });
  }
}
