<div
  id="burger-bar"
  tid="burger-bar"
  *ngIf="{
    burgerBar: appLayoutStateService.burgerBar$ | async,
    burgerBarItems: burgerBarService.burgerBarItems$ | async,
    activeProduct: activeProductService.activeProduct$ | async
  } as state"
>
  <nav tid="burger-bar-menu" *ngIf="state.burgerBar && state.burgerBarItems">
    <ul>
      <ng-container *ngFor="let item of state.burgerBarItems">
        <div *ifFeatureFlagged="item.featureFlag ? item.featureFlag : 'none'; showIf: item.featureFlagBooleanValue || 'on'">
          <ng-container *ngIf="item.anyApplicationPermissions">
            <li [ngClass]="{ active: isActive(item) }" *ifAuthorisedAnyApplicationPermissions="item.anyApplicationPermissions">
              <app-burger-bar-list-item
                *ngIf="item.enabledAtOrganisation === undefined || item.enabledAtOrganisation"
                [item]="item"
              ></app-burger-bar-list-item>
            </li>
          </ng-container>
          <ng-container *ngIf="item.anyOrganisationPermissions">
            <li [ngClass]="{ active: isActive(item) }" *ifAuthorisedAnyOrganisationPermissions="item.anyOrganisationPermissions">
              <app-burger-bar-list-item
                *ngIf="item.enabledAtOrganisation === undefined || item.enabledAtOrganisation"
                [item]="item"
              ></app-burger-bar-list-item>
            </li>
          </ng-container>
          <ng-container *ngIf="item.allOrganisationPermissions">
            <li [ngClass]="{ active: isActive(item) }" *ifAuthorisedAllOrganisationPermissions="item.allOrganisationPermissions">
              <app-burger-bar-list-item
                *ngIf="item.enabledAtOrganisation === undefined || item.enabledAtOrganisation"
                [item]="item"
              ></app-burger-bar-list-item>
            </li>
          </ng-container>
          <ng-container *ngIf="item.anyBureauPermissions">
            <li [ngClass]="{ active: isActive(item) }" *ifAuthorisedAnyBureauPermissions="item.anyBureauPermissions">
              <app-burger-bar-list-item
                *ngIf="item.enabledAtOrganisation === undefined || item.enabledAtOrganisation"
                [item]="item"
              ></app-burger-bar-list-item>
            </li>
          </ng-container>
          <ng-container
            *ngIf="state.activeProduct !== 'EmployeeHub' && state.activeProduct !== 'AdminHub' && item.anyFeaturePackPermissions"
          >
            <li [ngClass]="{ active: isActive(item) }" *ifAuthorisedAnyFeaturePackPermissions="item.anyFeaturePackPermissions">
              <app-burger-bar-list-item
                *ngIf="item.enabledAtOrganisation === undefined || item.enabledAtOrganisation"
                [item]="item"
              ></app-burger-bar-list-item>
            </li>
          </ng-container>

          <ng-container *ngIf="item.anyEmploymentFeaturePackPermissions">
            <li
              [ngClass]="{ active: isActive(item) }"
              *ifAuthorisedAnyEmploymentFeaturePackPermissions="item.anyEmploymentFeaturePackPermissions"
            >
              <app-burger-bar-list-item
                *ngIf="item.enabledAtOrganisation === undefined || item.enabledAtOrganisation"
                [item]="item"
              ></app-burger-bar-list-item>
            </li>
          </ng-container>
        </div>
      </ng-container>

      <li>
        <a
          tid="help-link"
          id="help-button"
          [href]="getProductHelpUrl(state.activeProduct)"
          target="_blank"
          title="Go to the help and support portal"
          tabindex="0"
        >
          <button class="burger-button">
            <app-body-xs ink="ink-100">Help</app-body-xs>
          </button>
        </a>
      </li>

      <ng-container *ngIf="appLayout.accountMenu$ | async as accountMenuItems">
        <li
          *ngFor="let action of accountMenuItems.actions"
          (click)="action.execute && action.execute()"
          id="{{ action.buttonId }}"
          class="{{ action.buttonClass }}"
          tabindex="0"
        >
          {{ action.label }}
        </li>
      </ng-container>
      <li *ngIf="isImpersonated()" (click)="endUserImpersonation()" tabindex="0">End Impersonation</li>
    </ul>
  </nav>
</div>
