<ng-container *ngIf="hasMultiple && activeEmployment">
  <button tid="employment-menu-button" [matMenuTriggerFor]="employmentMenu" class="menu-trigger" tabindex="0" #menuTrigger>
    <span>{{ activeMembership.organisationName }}</span>
    <mat-icon class="trigger-icon">arrow_drop_down</mat-icon>
  </button>

  <mat-menu #employmentMenu tid="employment-menu" class="employment-menu context-menu">
    <button
      *ngFor="let employment of employments"
      mat-menu-item
      (click)="select(employment)"
      [attr.data-employment-id]="employment.myEmploymentModel.employmentId"
      [attr.data-organisation-id]="employment.myEmploymentModel.organisationGuid"
      [attr.data-employee-id]="employment.myEmploymentModel.id"
      tabindex="0"
      style=""
    >
      <span class="employment">
        <app-body class="organisation" [semiBold]="true" tid="employment-selector-employer">{{ employment.organisationName }}</app-body>
        <app-body-xs class="period" [semiBold]="true">{{ getEmploymentPeriod(employment.myEmploymentModel) }}</app-body-xs>
      </span>
      <app-body-xs [semiBold]="true" tid="employment-selector-employer">{{ employment.myEmploymentModel.employer.name }}</app-body-xs>
    </button>
    <app-body-md [bold]="true" id="no-results" *ngIf="!employments.length">No employments found</app-body-md>
  </mat-menu>
</ng-container>

<div class="selected-label" *ngIf="hasMultiple === false && !!activeMembership">
  <span>{{ activeMembership.organisationName }}</span>
</div>
