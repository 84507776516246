import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared.module';
import { AccountExpiringBannerComponent } from './account-expiring/account-expiring-banner.component';
import { NoConnectionBannerComponent } from './no-connection/no-connection-banner.component';

@NgModule({
  declarations: [AccountExpiringBannerComponent, NoConnectionBannerComponent],
  imports: [SharedModule],
  exports: [AccountExpiringBannerComponent, NoConnectionBannerComponent]
})
export class BannersModule {}
