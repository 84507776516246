<ng-container *ngIf="hasMultiple$ | async">
  <button tid="bureau-menu-button" [matMenuTriggerFor]="bureauMenu" class="menu-trigger" tabindex="0" (click)="focusSearch()">
    <span *ngIf="activeSecurity.activeBureau$ | async as activeBureau">{{ activeBureau.name }}</span>
    <mat-icon class="trigger-icon">arrow_drop_down</mat-icon>
  </button>

  <form [formGroup]="formGroup">
    <mat-menu #bureauMenu tid="organisation-menu" class="context-menu">
      <app-search-input
        class="search-input"
        #searchInput
        formPropertyName="searchTerm"
        (click)="$event.stopPropagation()"
        (keydown)="$event.stopPropagation()"
        [autocomplete]="'off'"
        placeholder="Search"
        tabindex="0"
        forContextMenu="true"
      ></app-search-input>
      <button *ngFor="let bureau of filterSet$ | async" mat-menu-item (click)="changeBureau(bureau)" tabindex="0">
        {{ bureau.name }}
      </button>
      <app-body-md bold="bold" id="no-results" *ngIf="!resultCount">No bureaux found</app-body-md>
    </mat-menu>
  </form>
</ng-container>
<ng-container *ngIf="(hasMultiple$ | async) === false">
  <div class="selected-label" *ngIf="activeSecurity.activeBureau$ | async as activeBureau" tid="single-bureau-label">
    <span>{{ activeBureau.name }}</span>
  </div>
</ng-container>
