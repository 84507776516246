<div class="equifax-disclosure-acknowledgement-modal">
  <header>
    <app-icon class="close-icon" color="white" hoverColor="white" (click)="onClose()" tid="close-modal-icon" clickable="true">
      close
    </app-icon>
  </header>

  <div class="content">
    <h3>Employee Verification Service</h3>
    <div class="disclosure-info">
      <p>
        In partnership with Equifax Workforce Solutions, Cintra enables a service called Employee Verification that simplifies the process
        for employees applying for loans, mortgages and tenancy agreements etc.
      </p>

      <h4>What is Employee Verification?</h4>
      <p>
        It is a service that allows employees to authorise a bank, mortgage lender or letting agency etc. to request confirmation of their
        income and/or employment status.
      </p>

      <h4>How does my organisation benefit from it?</h4>
      <p>
        Employee Verification eliminates the tedious and time-consuming burden of gathering physical evidence of income and employment.
        Instead, the employee data is verified in real time with a secure digital service.
      </p>

      <p>
        If you do not wish to benefit from Employee Verification, you can opt out by visiting the ‘Organisation settings’ page under
        ‘Settings’ on the menu.
      </p>
    </div>
    <div class="disclosure-links">
      <a href="https://pssg.zendesk.com/hc/en-gb/categories/17442501640977-Employee-Verification-Service" target="_blank">
        <mat-icon> list_alt </mat-icon>
        See more details</a
      >
    </div>

    <div class="disclosure-checkbox">
      <mat-checkbox class="white-checkbox" (change)="checked()"></mat-checkbox>
      <p>I have read this message and understand the action required if my organisation does not want to participate.</p>
    </div>
  </div>

  <footer class="row g-0">
    <div class="col d-flex justify-content-center mt-2 mb-3">
      <app-button
        theme="system-green"
        tabindex="0"
        (click)="acknowledgeDisclosureButtonClick()"
        tid="acknowledge-disclosure-button"
        [disabled]="disclosureButtonDisabled"
      >
        I UNDERSTAND
      </app-button>
    </div>
  </footer>
</div>
