<ng-container
  *ngIf="{
    activeMembership: activeSecurity.activeMembership$ | async,
    burgerBar: appLayoutService.burgerBar$ | async,
    rightSidebar: appLayoutService.rightSidebar$ | async
  } as state"
>
  <ng-container *ngIf="!!state.activeMembership">
    <app-sales-popup></app-sales-popup>

    <app-header></app-header>

    <div id="burger-bar-overlay" [ngClass]="{ visible: state.burgerBar.visible }">
      <div id="burger-bar-panel">
        <app-burger-bar id="burger-bar-left-sidenav" tid="burger-bar-container"></app-burger-bar>
      </div>
    </div>
  </ng-container>
  <div id="app-content-panel" (scroll)="scrollPage($event)" [ngClass]="{ 'right-side-bar-visible': state.rightSidebar.visible }">
    <div id="banners">
      <app-banner
        *ngFor="let banner of (bannerService.banners$ | async).entries()"
        [bannerName]="banner[0]"
        [contentType]="banner[1]"
      ></app-banner>
    </div>
    <router-outlet></router-outlet>
  </div>

  <div id="right-side-bar-panel" [ngClass]="{ visible: state.rightSidebar.visible }">
    <app-right-sidebar></app-right-sidebar>
  </div>
</ng-container>

<router-outlet name="modal"></router-outlet>
<router-outlet name="secondaryModal"></router-outlet>
<router-outlet name="tertiaryModal"></router-outlet>
<router-outlet name="message"></router-outlet>
<router-outlet name="selector"></router-outlet>
