import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { AppLayoutStateService } from '@app/app-layout/app-layout.state-service';
import { TopNavigationLink } from '@app/app-layout/header/product-navigation/navigation-bar/navigation-bar';
import { HolidayApproverSummary } from 'src/app/employee-hub/employee-leave/leave-approvals';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActiveSecurityContextStateService } from '@security/active-security/active-security-context.state-service';
import { BehaviorSubject } from 'rxjs';
import { EmployeeHubNavigationService } from '@app/app-layout/header/product-navigation/employee-hub-navigation/employee-hub-navigation.service';

@UntilDestroy()
@Component({
  selector: 'app-employee-hub-navigation',
  templateUrl: './employee-hub-navigation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeHubNavigationComponent {
  topNavigationLinks$ = new BehaviorSubject<TopNavigationLink[]>([]);

  constructor(
    public activeSecurity: ActiveSecurityContextStateService,
    public employeeHubNavigationService: EmployeeHubNavigationService,
    appLayoutService: AppLayoutStateService
  ) {
    appLayoutService.holidayApproverSummary$.pipe(untilDestroyed(this)).subscribe(this.createEmployeeHubLinks);
  }

  createEmployeeHubLinks = (approverSummary: HolidayApproverSummary) => {
    const topNavigationLinks = this.employeeHubNavigationService.fetchEmployeeHubNavigation(approverSummary);
    this.topNavigationLinks$.next(topNavigationLinks);
  };
}
