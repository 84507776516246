import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { RightSidebarService } from './right-sidebar.service';

@Component({
  selector: 'app-right-sidebar',
  templateUrl: './right-sidebar.component.html'
})
export class RightSidebarComponent {
  private activationEvent: any;
  constructor(
    public router: Router,
    public rightSidebarService: RightSidebarService
  ) {}

  onRouteActivated(evt) {
    this.rightSidebarService.toggleRightSidebar(true);
    this.activationEvent = evt;
  }

  onRouteDeactivated() {
    this.activationEvent = undefined;
    setTimeout(() => {
      if (!this.activationEvent) this.rightSidebarService.toggleRightSidebar(false);
    }, 100);
  }
}
