import { ChangeDetectionStrategy, Component, OnInit, Inject } from '@angular/core';
import { ActiveSecurityContextStateService } from '@security/active-security/active-security-context.state-service';
import { ScrollService } from '@utils/scroll-utils.service';
import { BannerService } from '@design/layout/banner-layout/banner.service';
import { AppLayoutStateService } from '@app/app-layout/app-layout.state-service';
import { MatDialog } from '@angular/material/dialog';
import { EquifaxDisclosureAcknowledgementComponent } from '@app/equifax/equifax-disclosure-acknowledgement.component';
import { FeatureFlagService, FeatureFlagsServiceInjectionToken } from '@feature-flags/feature-flag.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest } from 'rxjs';
import { FeatureFlagType } from '@feature-flags/feature-flag.service';

@UntilDestroy()
@Component({
  selector: 'app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppLayoutComponent implements OnInit {
  constructor(
    public appLayoutService: AppLayoutStateService,
    public activeSecurity: ActiveSecurityContextStateService,
    public scrollService: ScrollService,
    public bannerService: BannerService,
    private dialog: MatDialog,
    @Inject(FeatureFlagsServiceInjectionToken) private featureFlagService: FeatureFlagService
  ) {}

  ngOnInit() {
    combineLatest([this.featureFlagService.getValue$('equifaxOptOut', false), this.appLayoutService.hasAcknowledgedEquifaxDisclosure$])
      .pipe(untilDestroyed(this))
      .subscribe(([equifaxOptOut, hasAcknowledgedEquifaxDisclosure]) => {
        if (this.shouldShowEquifaxDisclosureDialog(equifaxOptOut, hasAcknowledgedEquifaxDisclosure)) {
          this.dialog.open(EquifaxDisclosureAcknowledgementComponent, {
            panelClass: 'equifax-disclosure-acknowledgement-modal-panel'
          });
        }
      });
  }

  private shouldShowEquifaxDisclosureDialog(equifaxOptOut: FeatureFlagType, hasAcknowledgedEquifaxDisclosure: boolean): boolean {
    return (
      equifaxOptOut &&
      this.activeSecurity.isCintraEmployee() === false &&
      this.activeSecurity.hasBureauProfilePermissions() === false &&
      this.activeSecurity.hasOrganisationAuthorityTo('EquifaxDataConsent') &&
      hasAcknowledgedEquifaxDisclosure === false
    );
  }

  scrollPage($event) {
    this.scrollService.scroll($event);
  }
}
