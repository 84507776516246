import { ChangeDetectionStrategy, Component, Input, ElementRef } from '@angular/core';
import { FocusableOption } from '@angular/cdk/a11y';

import { BurgerBarService } from '../burger-bar.service';
import { RouteAction } from '@design/buttons/action-config';
import { toKebabCase } from '@utils/string-utils';

@Component({
  selector: 'app-burger-bar-list-item',
  templateUrl: './burger-bar-list-item.component.html',
  styleUrls: ['./burger-bar-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BurgerBarListItemComponent implements FocusableOption {
  @Input() item!: RouteAction;
  @Input() isActive = false;
  disabled = false;

  constructor(
    public burgerBarService: BurgerBarService,
    private element: ElementRef
  ) {}

  focus(): void {
    this.element.nativeElement.focus();
  }

  getTestId(label: string): string {
    return `${toKebabCase(label)}-burger-bar-item`;
  }

  navigateItem = (item) => {
    if (item.execute) {
      item.execute();
    } else {
      this.burgerBarService.navigate(item);
    }
  };
}
