import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, of, take } from 'rxjs';
import { RouteAction } from '@design/buttons/action-config';
import { LoggingService } from '@logging/logging.service';
import { BurgerBarConfig } from './burger-bar.config';
import { AppLayoutStateService } from '../app-layout.state-service';
import { ActiveSecurityContextStateService } from '@security/active-security/active-security-context.state-service';
import { ActiveCintraProductService } from '@app/active-cintra-product.service';
import { CintraProduct } from '@app/cintra-product';
import { Member } from '@security/users/models/member';
import { NavigationRoutingService } from '@utils/navigation-routing.service';
import { FeatureFlagService, FeatureFlagsServiceInjectionToken } from '@feature-flags/feature-flag.service';
import { EmployeeHubNavigationService } from '@app/app-layout/header/product-navigation/employee-hub-navigation/employee-hub-navigation.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AdminHubNavigationService } from '@app/app-layout/header/product-navigation/admin-hub-navigation/admin-hub-navigation.service';
import { TopNavigationLink } from '@app/app-layout/header/product-navigation/navigation-bar/navigation-bar';
import { CintraPayNavigationService } from '@app/app-layout/header/product-navigation/cintra-pay-navigation/cintra-pay-navigation.service';
import { AppClientTypesStateService } from '@app/integrations-admin/integrations/shared/state/app-client-types/app-client-types.state-service';
import { BureauNavigationService } from '@app/app-layout/header/product-navigation/bureau-navigation/bureau-navigation.service';

/**
 * Service for managing the models of the {@link BurgerBarComponent}
 * Items are configured by {@link BurgerBarConfig} and set up in {@link CoreModule}
 */

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class BurgerBarService {
  burgerBarItems$ = new BehaviorSubject<RouteAction[]>(undefined);

  constructor(
    private activeSecurity: ActiveSecurityContextStateService,
    public activeCintraProductService: ActiveCintraProductService,
    public appLayoutStateService: AppLayoutStateService,
    private router: Router,
    private navigationRoutingService: NavigationRoutingService,
    private logger: LoggingService,
    public employeeHubNavigationService: EmployeeHubNavigationService,
    public adminHubNavigationService: AdminHubNavigationService,
    public cintraPayNavigationService: CintraPayNavigationService,
    public bureauNavigationService: BureauNavigationService,
    private appClientTypeStateService: AppClientTypesStateService,
    @Inject(FeatureFlagsServiceInjectionToken) private featureFlagService: FeatureFlagService
  ) {
    combineLatest([activeSecurity.activeMembership$, activeCintraProductService.activeProduct$]).subscribe(
      ([member, product]: [Member, CintraProduct]) => {
        if (product === 'EmployeeHub') {
          this.buildEmployeeHubItems();
        } else if (product === 'AdminHub') {
          this.burgerBarItems$.next(this.adminHubNavigationService.fetchAdminHubNavigation());
        } else if (product === 'Bureau') {
          this.buildBureauItems();
        } else if (product === 'Payroll') {
          this.buildPayrollItems(member);
        }
      }
    );

    this.watchForNavigationChanges();
  }

  private buildPayrollItems = (member: Member) => {
    let toResolve = {
      featureFlags: this.featureFlagService.getAllValues$().pipe(take(1)),
      hrEnabled: of(false) as any
    };

    if (this.activeSecurity.hasOrganisationAuthorityTo('AccessPayrolls')) {
      toResolve.hrEnabled = this.appClientTypeStateService.hasHrEnabled$();
    }

    combineLatest(toResolve).subscribe((result) => {
      const payrollLinks = this.cintraPayNavigationService.fetchCintraPayNavigation(
        result.featureFlags,
        result.hrEnabled as boolean,
        member
      );
      const formattedLinks = this.formatLinksForBurgerBar(payrollLinks);
      this.burgerBarItems$.next(formattedLinks);
    });
  };

  private buildBureauItems = () => {
    this.featureFlagService
      .getAllValues$()
      .pipe(take(1))
      .subscribe((featureFlags) => {
        const navigationLinks = this.bureauNavigationService.fetchBureauNavigation(featureFlags);
        this.burgerBarItems$.next(this.formatLinksForBurgerBar(navigationLinks));
      });
  };

  private buildEmployeeHubItems = () => {
    this.appLayoutStateService.holidayApproverSummary$.pipe(untilDestroyed(this)).subscribe((approverSummary) => {
      const navigationLinks = this.employeeHubNavigationService.fetchEmployeeHubNavigation(approverSummary);
      this.burgerBarItems$.next(this.formatLinksForBurgerBar(navigationLinks));
    });
  };

  private formatLinksForBurgerBar = (navigationLinks: TopNavigationLink[]) => {
    let burgerBarItems: RouteAction[] = [];

    navigationLinks.forEach((link) => {
      if (link.sectionLinks && link.sectionLinks.length > 0) {
        link.sectionLinks.forEach((sectionLink) => {
          burgerBarItems.push({
            label: link.label + ' - ' + sectionLink.label,
            route: sectionLink.route,
            feature: sectionLink.feature,
            anyEmploymentFeaturePackPermissions: sectionLink.anyEmploymentFeaturePackPermissions,
            anyOrganisationPermissions: sectionLink.anyOrganisationPermissions,
            anyApplicationPermissions: sectionLink.anyApplicationPermissions,
            anyBureauPermissions: sectionLink.anyBureauPermissions,
            allBureauPermissions: sectionLink.allBureauPermissions,
            section: sectionLink.section,
            execute: sectionLink.execute ? (data) => sectionLink.execute(data) : undefined
          });
        });
      } else {
        burgerBarItems.push({
          label: link.label,
          route: link.route,
          feature: link.feature,
          anyEmploymentFeaturePackPermissions: link.anyEmploymentFeaturePackPermissions,
          anyOrganisationPermissions: link.anyOrganisationPermissions,
          anyApplicationPermissions: link.anyApplicationPermissions,
          anyBureauPermissions: link.anyBureauPermissions,
          allBureauPermissions: link.allBureauPermissions,
          section: link.section,
          execute: link.execute ? (data) => link.execute(data) : undefined
        });
      }
    });

    return burgerBarItems;
  };

  /**
   * Watch for app navigation route changes so that the active burger bar menu item can be highlighted
   */
  private watchForNavigationChanges() {
    /** watch for route changes and set the relevant menu item as current */
    this.navigationRoutingService.navigationEvents$.subscribe((evt) => {
      const burgerBarItems = this.burgerBarItems$.value;
      if (!burgerBarItems) return;

      const routeData = this.navigationRoutingService.getRouteData();

      let currentAction = burgerBarItems.find((item) => item.feature === routeData.feature || item.section === routeData.section);

      this.appLayoutStateService.updateBurgerBar({ activeItem: currentAction });
      this.logger.trace('BurgerBarService: current link:', currentAction);
    });
  }

  /**
   * Called when the user clicks a menu item. Ensures the burger bar is closed by toggling models.
   */
  navigate(routeAction: RouteAction) {
    const route = routeAction.route;
    this.logger.trace('BURGER BAR: navigating: ', route);
    this.toggleBurgerBar(false);
    this.router.navigateByUrl(route).then();
  }

  /**
   * Toggle the burger bar; optionally force the models by parameter
   */
  toggleBurgerBar(opened?: boolean) {
    this.logger.trace('BURGER BAR: toggling > ', opened);
    const burgerBar = this.appLayoutStateService.getBurgerBar();
    const visible = opened === undefined ? !burgerBar.visible : opened;

    this.logger.trace('BURGER BAR: now visible? ', visible);

    this.appLayoutStateService.updateBurgerBar({ visible });
  }
}
