import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared.module';
import { SalesPopupComponent } from './sales-popup/sales-popup.component';

@NgModule({
  declarations: [SalesPopupComponent],
  exports: [SalesPopupComponent],
  imports: [SharedModule]
})
export class SalesModule {}
