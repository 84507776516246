import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { EquifaxDisclosureAcknowledgementDataProvider } from './equifax-disclosure-acknowledgement-data-provider';

@Component({
  selector: 'app-equifax-disclosure-acknowledgement',
  templateUrl: './equifax-disclosure-acknowledgement.component.html',
  styleUrls: ['./equifax-disclosure-acknowledgement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EquifaxDisclosureAcknowledgementComponent {
  public disclosureButtonDisabled = true;

  constructor(
    private dialogRef: MatDialogRef<EquifaxDisclosureAcknowledgementComponent>,
    private dataProvider: EquifaxDisclosureAcknowledgementDataProvider
  ) {}

  acknowledgeDisclosureButtonClick = () => {
    if (this.disclosureButtonDisabled) return;
    this.dataProvider.create$().subscribe(() => {
      this.dialogRef.close(true);
    });
  };

  checked = () => {
    this.disclosureButtonDisabled = !this.disclosureButtonDisabled;
  };

  onClose = () => {
    this.dialogRef.close(false);
  };
}
